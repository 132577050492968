
.el-int {
    width: 250px;
    margin-left: 20px;
}

.activeClass {
    background: #2338e6;
    color: #ffffff;
}

.company-contain {
    display: flex;
    flex-direction: column;
    background: #fff;

    .head-box {
        padding: 24px;
        background: #fff;

        .time-box {
            display: flex;
            align-items: center;

            .day-type {
                margin-right: 6px;
            }
        }

        .sort-box {
            margin-top: 16px;
            display: flex;
            align-items: center;

        }

        .time-box,
        .sort-box {

            .time-label,
            .sort-label {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #252632;
                line-height: 16px;
            }

            gap: 10px;

            .radio-group {
                padding: 4px;
                border-radius: 4px;
                background-color: #f2f2f4;

                ::v-deep .el-radio-button__orig-radio+.el-radio-button__inner {
                    background-color: transparent;
                    color: rgba(37, 38, 50, 0.6);
                    font-size: 14px;
                    font-weight: 400;
                    border-color: transparent;
                    box-shadow: none;
                    border-radius: 4px;
                    padding: 2px 24px;
                }

                ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                    background-color: #ffffff;
                    color: #594FEE;
                }

                ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
                    color: #594FEE;
                }

                ::v-deep .el-radio-button__inner:hover {
                    color: #2338e6;
                }
            }

            .time-filter-box {
                ::v-deep .el-input__inner {
                    background-color: #f2f2f4;
                    color: #252632;
                    border: none;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                }

                ::v-deep .el-input__icon {
                    line-height: 28px;
                }

            }
        }
    }

    .medio-contain {
        height: calc(100vh - 222px);
        // background-color: #a3a3c4;
        box-sizing: border-box;
        padding: 0 24px;

        ::v-deep .el-scrollbar .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .medio-list {
            width: 100%;
            height: 100%;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 16px;
            // box-sizing: border-box;


        }

        .medio-load {
            display: flex;
            justify-content: center;
            padding: 25px 0;

            span {
                font-weight: 400;
                font-size: 16px;
                color: #888888;
                line-height: 19px;
                cursor: pointer;
            }
        }
    }
}

::v-deep .el-table th.el-table__cell>.cell {
    padding-right: 27px;
    padding-left: 27px;


}

::v-deep .el-table .cell {
    padding-right: 27px;
    padding-left: 27px;


}
